import {request,request2} from "@/utils/axios";


//登录的接口
//登录校验
export function memberLogin(params){
    return request.post('/portal/service/member/memberLogin.do',params)    
 }

//  {
//    "loginPassword": "string",
//    "memberLoginTypeEnum": "USERNAME_PASSWORD",
//    "phoneNum": "string",
//    "smsCode": "string",
//    "userName": "string",
//    "wxCode": "string"
//  }

//用户注销
export function memberLogout(){
    return request.post('/portal/service/member/memberLogout.do')    
 }
 
//  生成imei字段的接口
export function getImei(){
    return request.post('/portal/service/member/getPcImeiInfo.do')    
 }

//获取用户个人信息
//登录校验
export function getMemberSettingInfo(){
   return request.post('/portal/service/member/getMemberInfo4Pc.do')    
}
// trialApplyStatus	string
// NONE:未申请; 可再申请
// FIRST_REVIEW:初次申请审核中;
// FIRST_PASSED:初次申请已通过;
// FIRST_REJECTED:初次申请已拒绝; 可再申请
// FIRST_EXCEED:初次申请已到期; 可再申请
// SECOND_REVIEW:再次申请审核中;
// SECOND_PASSED:再次申请已通过;
// SECOND_REJECTED:再次申请已拒绝; 可再申请
// SECOND_EXCEED:再次申请已到期;

//查询指定参数的用户注册信息
export function queryUserRegisterInfoBySpecifyProp(params){
   return request.post('/portal/service/member/queryUserRegisterInfoBySpecifyProp.do',params)    
}
//用户id  private Long userId;
//参数key列表 private List<String> codeList;

//查询指定参数的用户认证信息
export function queryUserAuthenticationInfo(params){
   return request.post('/portal/service/member/queryUserAuthenticationInfo.do',params)    
}


//获取用户身份标签
export function getMemberUserIdentity(){
   return request.post('/portal/service/member/getMemberUserIdentity.do')    
}
//expireTime
//expireTimeStamp
//userVipType 用户VIP类型(TRIAL_VIP:试用VIP;COMMON_VIP:普通VIP)

  //生成online域名
  export function getOnlineDomain(){
    return request.post('/portal/service/member/getEmOnlineServerUrl.do')    
 }

 //? 用户注册认证
 //提交注册
 export function register(params){
   return request.post('/portal/service/member/register.do',params)    
}
// {
//    "applicationScene": "string",
//    "company": "string",
//    "confirmPassword": "string",
//    "mail": "string",
//    "motorType": "string",
//    "password": "string",
//    "phone": "string",
//    "position": "string",
//    "productType": "string",
//    "realName": "string",
//    "smsCode": "string",
//    "unitNature": "string",
//    "userName": "string"
//  }

 //注册选项
 export function registerPreInfo(params){
   return request.post('/portal/service/member/registerPreInfo.do',params)    
}
//注册企业信息列表
export function registerQueryEnterpriseBusinessList(params){
   return request.post('/portal/service/member/registerQueryEnterpriseBusinessList.do',params)    
}
 //注册参数预检
 export function registerCheck(params){
   return request.post('/portal/service/member/registerCheck.do',params)    
}
// company	公司
// mail	邮箱
// password	密码
// phone	手机号
// position	职位
// realName	真实姓名
// smsCode	验证码手机号(手机号-短信方式)
// userName	账户名

 //认证参数预检
 export function fillInfoPreCheck(params){
   return request.post('/portal/service/member/fillInfoPreCheck.do',params)    
}

//提交认证
export function registerAuthentication(params){
   return request.post('/portal/service/member/registerAuthentication.do',params)    
}
// {
//    "company": "string",
//    "memberCode": "string",
//    "position": "string",
//    "realName": "string"
//  }



 //!todo 用户设置
 //原密码+新密码重置
 export function resetLoginPassword(params){
   return request.post('/portal/service/member/resetLoginPassword.do',params)    
}
// {
//    "newPassword": "string",
//    "oldPassword": "string"
//  }

 //验证码+新密码重置
 export function resetLoginPasswordWithSmsCode(params){
   return request.post('/portal/service/member/resetLoginPasswordWithSmsCode.do',params)    
}
// {
//    "newPassword": "string",
//    "smsCode": "string"
//  }

 //发送短信验证码(登陆状态)
 export function sendSmsCodeOnLoggedIn(params){
   return request.post('/portal/service/member/sendSmsCodeOnLoggedIn.do',params)    
}
// {
//    "businessType": "RESET_PASSWORD",
//  }

 //发送短信验证码(非登陆状态)
 export function sendSmsCaptcha(params){
   return request.post('/portal/service/member/sendSmsCaptcha.do',params)    
}
// {
//    "businessType": "REGISTER",
//    "mobileNo": "string"
//  }

 //发送邮箱验证码(注册状态)
 export function registerCodeMail(params){
   return request.post('/portal/service/member/registerCodeMail.do',params)    
}
// {
//    "businessType": "REGISTER",
//    "mobileNo": "string"
//  }

 //发送邮箱验证码(认证状态)
 export function fillInfoCodeMail(params){
   return request.post('/portal/service/member/fillInfoCodeMail.do',params)    
}

 //修改用户信息
 export function updateUserInfo(params){
   return request.post('/portal/service/member/updateUserInfo.do',params)    
}
// {
//    "address": "string",
//    "company": "string",
//    "headPic": "string",
//    "mail": "string",
//    "phone": "string",
//    "position": "string",
//    "realName": "string",
//    "userLanguage": "ZH_CN",
//    "userName": "string"
//  }

//发送短信验证码(修改手机号码)
 export function modifyPhone(params){
   return request.post('/portal/service/member/modifyPhone.do',params)    
}
// {
   // "phone": "string",
   // "smsCode": "string"
//  }

//验证码校验
export function checkSmsCaptcha(params){
   return request.post('/portal/service/member/checkSmsCaptcha.do',params)    
}
// {
   // "businessType": "REGISTER",
   // "mobileNo": "string",
   // "smsCode": "string"
//  }

//提交试用申请
export function applyTrail(params){
   return request.post('/portal/service/member/applyTrail.do',params)    
}

//再次提交试用申请
export function applyTrailSecond(params){
   return request.post('/portal/service/member/applyTrailSecond.do',params)    
}

//上传图片文件返回路径
export function shareUpload(params){
   return request.post('/portal/service/design/shareUpload.do',params)    
}



//查询用户核时统计数据
export function queryUserCalcTime(){
   return request.post('/portal/service/member/queryUserCalcTime.do')    
}

//用户多场耦合记录
export function updateMemberMultiPhysicsOperationRecord(params){
   return request.post('/portal/service/member/updateMemberMultiPhysicsOperationRecord.do',params)    
}
//查询商品信息
export function queryMultiFieldCouplingSkus(params){
   return request.post('/portal/service/goods/queryMultiFieldCouplingSkus.do',params)    
}

//查询用户套餐
export function queryUserPackage(){
   return request.post('/portal/service/member/queryUserPackage.do')    
}

//查询是否在免费活动期内
export function queryActivityFlag(params){
   return request.post('/portal/service/goods/queryActivityFlag.do',params)    
}

//?技术支持 
//技术支持发送邮件
export function technicalSupport(params){
   return request.post('/portal/service/member/technicalSupport.do',params)    
}
//ifCopyData-是否拷贝产品数据

//判断是否技术支持账户
export function checkSupportUser(){
   return request.post('/portal/service/member/checkSupportUser.do')    
}

//查询技术支持概要信息
export function queryProductSupportOverview(){
   return request.post('/portal/service/design/queryProductSupportOverview.do')    
}

//清除已过期的技术支持分享
export function clearExpiredSupport(){
   return request.post('/portal/service/design/clearExpiredSupport.do')    
}

//查询技术支持分享产品列表
export function queryProductSupport(params){
   return request.post('/portal/service/design/queryProductSupport.do',params)    
}

//技术支持分享产品已读
export function readProductSupport(params){
   return request.post('/portal/service/design/readProductSupport.do',params)    
}
//productUUID：产品编号

